//
//
//
//
//
//
//
//

export default {
  name: 'UiLinkApp',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
}
