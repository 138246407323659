//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BlokHomeTiles',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisibleAos: false,
    }
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    isText() {
      return this.blok.icon?.filename || this.blok.title || this.blok.description || this.blok.apps?.length;
    },
    table() {
      const res = [];
      const tiles_middle = this.blok.tiles_middle;
      const tiles_left = this.blok.tiles_left;
      const tiles_right = this.blok.tiles_right;

      if (tiles_middle?.length) {
        res.push({ name: 'middle', list: tiles_middle });
      }
      if (tiles_left?.length) {
        res.push({ name: 'left', list: tiles_left });
      }
      if (tiles_right?.length) {
        res.push({ name: 'right', list: tiles_right });
      }

      return res;
    },
  },
  methods: {
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
  },
}
